import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import {
  get_one_gatepass,
  get_one_gatepass_clear,
  getOnegatepassSelector,
} from "@services/redux";
import { Box, HStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GeneratePass from "../generate_pass/generate_pass";
import { Button } from "antd";
import GenerateTransitPass from "../generate_pass/generate_transit_pass";
import GenerateVehiclePass from "../generate_pass/generate_vehicle_pass";
import GenerateTwoWheelerPass from "../generate_pass/generate_two_wheeler";
import { useHistory } from "react-router-dom";
import PassLoading from "./loader";
import { ROUTES } from "@views/routes/my_routes";

const DownloadPass = () => {
  const { item: gate_pass, loading } = useSelector(getOnegatepassSelector);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(true);
  const vip_pass_ref = useRef(null);
  const parking_pass_ref = useRef(null);
  const transit_pass_ref = useRef(null);
  const two_wheeler_pass_ref = useRef(null);

  const triggerDownload = () => {
    const link = document.createElement("a");

    if (vip_pass_ref.current) {
      link.href = vip_pass_ref.current;
    } else if (parking_pass_ref.current) {
      link.href = parking_pass_ref.current;
    } else if (transit_pass_ref.current) {
      link.href = transit_pass_ref.current;
    } else if (two_wheeler_pass_ref.current) {
      link.href = two_wheeler_pass_ref.current;
    } else {
      return; // No valid download link, skip further actions
    }

    link.download = "Generate_pass.pdf";
    link.click();
    setIsDownloading(false);
    history.push(ROUTES.EMPTY);
    dispatch(get_one_gatepass_clear());
  };
  useEffect(() => {
    dispatch(
      get_one_gatepass({ id: window.location.pathname.split("_")?.[1] })
    );
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      if (
        (gate_pass && vip_pass_ref.current) ||
        (gate_pass && parking_pass_ref.current) ||
        (gate_pass && transit_pass_ref.current) ||
        (gate_pass && two_wheeler_pass_ref.current)
      ) {
        triggerDownload();
      }
    }, 2000);
  }, [
    gate_pass,
    vip_pass_ref,
    parking_pass_ref,
    transit_pass_ref,
    two_wheeler_pass_ref,
  ]);

  return (
    <div>
      {isDownloading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100vw"
        >
          <PassLoading />
        </Box>
      ) : (
        ""
      )}
      <HStack space={"10px"} position={"relative"} mt={"10000vh"}>
        {gate_pass?.status === "active" &&
          gate_pass?.entity_state?.entity?.name ===
            "Secretariat Staff / Public Pass " &&
          gate_pass?.pass_type?.name === "Parking" &&
          gate_pass?.pass_type?.parent_pass_type?.name ===
            "Four Wheeler Pass" && (
            <BlobProvider document={<GeneratePass pass_item={gate_pass} />}>
              {({ url }) => {
                parking_pass_ref.current = url; // Store the URL for programmatic access
                return (
                  <Button
                    style={{ display: !isDownloading ? "none" : "block" }}
                  >
                    Download Pass
                  </Button>
                );
              }}
            </BlobProvider>
            // <PDFDownloadLink
            //   fileName="Generate_pass.pdf"
            //   document={<GeneratePass pass_item={gate_pass} />} // parking pass
            // >
            //   <Button>{"Download Pass"}</Button>
            // </PDFDownloadLink>
          )}
        {gate_pass?.status === "active" &&
          gate_pass?.pass_type?.name === "Transit" &&
          (gate_pass?.entity_state?.entity?.name === "VIP/ HOD Pass" ||
            "Secretariat Staff / Public Pass ") && (
            <>
              <BlobProvider
                document={<GenerateTransitPass pass_item={gate_pass} />}
              >
                {({ url }) => {
                  transit_pass_ref.current = url; // Store the URL for programmatic access
                  return (
                    <Button
                      style={{ display: !isDownloading ? "none" : "block" }}
                    >
                      Download Pass
                    </Button>
                  );
                }}
              </BlobProvider>
              {/* <PDFDownloadLink
                fileName="Generate transit pass.pdf"
                document={<GenerateTransitPass pass_item={gate_pass} />} // transit pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink> */}
            </>
          )}
        {gate_pass?.status === "active" &&
          gate_pass?.entity_state?.entity?.name === "VIP/ HOD Pass" &&
          gate_pass?.pass_type?.name === "Parking" &&
          gate_pass?.pass_type?.parent_pass_type?.name ===
            "Four Wheeler Pass" && (
            <BlobProvider
              document={<GenerateVehiclePass pass_item={gate_pass} />}
            >
              {({ url }) => {
                vip_pass_ref.current = url; // Store the URL for programmatic access
                return (
                  <Button
                    style={{ display: !isDownloading ? "none" : "block" }}
                  >
                    Download Pass
                  </Button>
                );
              }}
            </BlobProvider>
          )}
        {gate_pass?.status === "active" &&
          (gate_pass?.entity_state?.entity?.name ===
            "Secretariat Staff / Public Pass " ||
            "VIP/ HOD Pass") &&
          gate_pass?.pass_type?.name === "Parking" &&
          gate_pass?.pass_type?.parent_pass_type?.name ===
            "Two Wheeler Pass" && (
            <BlobProvider
              document={<GenerateTwoWheelerPass pass_item={gate_pass} />}
            >
              {({ url }) => {
                two_wheeler_pass_ref.current = url; // Store the URL for programmatic access
                return (
                  <Button
                    style={{ display: !isDownloading ? "none" : "block" }}
                  >
                    Download Pass
                  </Button>
                );
              }}
            </BlobProvider>
            // <PDFDownloadLink
            //   fileName="Generate_pass.pdf"
            //   document={<GenerateTwoWheelerPass pass_item={gate_pass} />} // two wheeler pass
            // >
            //   <Button>{"Download Pass"}</Button>
            // </PDFDownloadLink>
          )}
      </HStack>
    </div>
  );
};

export default DownloadPass;
