import layout1 from "@views/layouts/layout1";
import Dashboard from "@views/pages/dashboard/dashboard";
// import login from "@views/pages/sign_in";
// import login from "@views/pages/sign_in";
import login from "@views/pages/sign_in";
import ChangePassword from "@views/pages/change_password";
import RefugeeLogin from "@views/pages/login/login";
import HomeDetails from "@views/pages/home/home_details";
// import AllotmentList from "@views/pages/allotment";
import VehicleList from "@views/pages/vehicle";
import VehicleDetails from "@views/pages/vehicle/vehicle_details";
import VehicleUpdate from "@views/pages/vehicle/vehicle_edit";
import VehicleAllotment from "@views/pages/vehicle/vehicle_allotment";

import ServiceList from "@views/pages/service/service_list";
import ServiceView from "@views/pages/service/service_request_details";
import ServiceHistory from "@views/pages/service/service_history";
import PositionList from "@views/pages/department/department_list";
import DEPARTMENT_LIST from "@views/pages/department/department_list";
import ServicePage from "@views/pages/service/service_request_view";
import CarMakeList from "@views/pages/carmake/carmake_list";
import CarMakeDetails from "@views/pages/carmake/carmake_details";
import CarModelList from "@views/pages/carmodel/car_model_list";
import AccessoriesList from "@views/pages/accessories/accessories_list";
import AgencyList from "@views/pages/agency/agency_list";
import Entity from "@views/pages/entity/entity";
import DesignationList from "@views/pages/designation/designation_list";
import ServiceQuestionList from "@views/pages/servicelist/service_question_list";
import ReasonDetails from "@views/pages/vehicle/vehicle_return_details";
import TyreList from "@views/pages/tyre/tyre_list";
import GatePassList from "@views/pages/gate_pass/gate_pass_list";
import GatePassDetails from "@views/pages/gate_pass/gate_pass_details";
import CondemnationList from "@views/pages/condemnation/condemnation_list";
import CondemnationDetails from "@views/pages/condemnation/condemnation_details";
import FuelList from "@views/pages/fuel/fuel_list";
import FuelDetails from "@views/pages/fuel/fuel_details";
import DriverList from "@views/pages/drivers/driver_list";
import DriverDetails from "@views/pages/drivers/driver_details";
import VehicleTab from "@views/pages/vehicle/vechicle_tab";
import ForgotPassword from "@views/pages/forgot_password/forgot_password";
import Notifications from "@views/pages/notifications/notifications";
import CondemnationView from "@views/pages/condemnation/condemnation_view";
import DriverView from "@views/pages/drivers/driver_view";
import FuelView from "@views/pages/fuel/fuel_view";
import GatePassView from "@views/pages/gate_pass/gate_pass_view";
import VehicleTabView from "@views/pages/vehicle/vehicle_tab_view";
import ScreenReaderAccess from "@views/pages/home/screen_reader_access";
import HomeLayout from "@views/layouts/home_layout";
import ReachedMeterReading from "@views/pages/condemnation_vehicles/reached_meter_reading";
import FuelCoupon from "@views/pages/fuel/fuel_coupon";
import ServiceNotGivenVehicles from "@views/pages/condemnation_vehicles/service_not_given";
import DriverHistory from "@views/pages/drivers/driver_history";
import AllocatedDashboard from "@views/pages/allocated_dashboard/allocated_dashboard";
import AllocatedDriverDashboard from "@views/pages/allocated_driver_dashboard/allocated_driver_dashboard";
import AllocatedDriversDashboard from "@views/pages/allocated_driver_dashboard/allocated_drivers_dashboard";
import OfficerList from "@views/pages/officers/officer_list";
import OfficerDetails from "@views/pages/officers/officer_details";
import PassForm from "@views/pages/gate_pass/pass_form";
import VehiclePassLogin from "@views/pages/new/vehicle_pass_login";
import VehiclePass from "@views/pages/new/vehicle_pass";
import PassHistoryView from "@views/pages/new/pass_history_view";
import GeneratePassView from "@views/pages/qr_validation/generate_pass";
import GenerateVehiclePassView from "@views/pages/qr_validation/generate_vehicle_pass";
import GenerateTransitPassView from "@views/pages/qr_validation/generate_transit_pass";
import GenerateTwoWheelerPassView from "@views/pages/qr_validation/generate_two_wheeler_pass";
import DownloadPass from "@views/pages/download_pass/download_pass";
export const ROUTES = {
  EMPTY: "/",
  DASHBOARD: "/dashboard",
  ALLOCATED_DASHBOARD: "/allocated_dashboard",
  SERVICE_REQUEST_LIST: "/service_list",
  DEPARTMENT_LIST: "/department",
  SERVICE_HISTORY_DETAILS: "/service_history",
  POSITION_LIST: "/position-list",
  VEHICLE_LIST: "/vehicle_list",
  CHANGE_PASSWORD: "/change-password",
  SERVICE_REQUEST_DETAILS: "/service_details",
  VEHICLE_LIST: "/vehicle_list",
  VEHICLE_DETAILS: "/vehicle_details",
  DESIGNATION_LIST: "/designation_list",
  VEHICLE_EDIT: "/vehicle_update",
  VEHICLE_ALLOTMENT: "/vehicle_allotment",
  CAR_MAKE_LIST: "/car_make",
  CAR_MAKE_DETAILS: "/car_make_details",
  CAR_MODEL_LIST: "/car_model",
  AGENCY_LIST: "/agency_list",
  ACCESSORIES_LIST: "/accessories_list",
  SERVICE_PAGE: "/service_page",
  CHANGE_PASSWORD: "/change-password",
  LOGIN: "/login",
  ENTITY: "/entity",
  SERVICE_LIST: "/service",
  RETURN_PAGE: "/vehicle_return",
  HOME_DETAILS: "/home",
  TYRE_LIST: "/tyre-list",
  BATTERY_LIST: "/battery_list",
  DESIGNATION_LIST: "/designation-list",
  GATE_PASS_DETAILS: "/gate-pass-details",
  GATE_PASS_LIST: "/gate-pass-list",
  CONDEMNATION_LIST: "/condemation-list",
  CONDEMNATION_DETAILS: "/condemation-details",
  FUEL_LIST: "/fuel-list",
  FUEL_DETAILS: "/fuel-details",
  DRIVER_LIST: "/driver_list",
  DRIVER_DETAILS: "/driver-details",
  VEHICLE_TAB: "/vehicle-tab",
  FORGOT_PASSWORD: "/forgot-password",
  NOTIFICATIONS: "/notifications",
  CONDEMNATION_VIEW: "/confirmation-view",
  DRIVER_VIEW: "/driver-view",
  FUEL_VIEW: "/fuel-view",
  GATE_PASS_VIEW: "/pass-view",
  VEHICLE_TAB_VIEW: "/vehicle-tab-view",
  SCREEN_READER_ACCESS: "/screen-reader-access",
  REACHED_METER_READING: "/meters-reading",
  FUEL_COUPON: "/fuel-coupon",
  SERVICE_NOT_GIVEN_VEHICLES: "/services-not-given-vehicles",
  DRIVER_HISTORY: "/driver-history",
  ALLOCATED_DRIVER_DASHBOARD: "/allocated_driver_dashboard",
  ALLOCATED_DRIVERS_DASHBOARD: "/allocated_driver_dashboard/department",
  OFFICERS: "/officers_list",
  OFFICERS_DETAILS: "/officers_details",
  PASS_FORM: "/pass-form",
  VEHICLE_PASS: "/vehicle_pass",
  VEHICLE_PASS_LOGIN: "/vehicle-pass-login",
  PASS_HISTORY_VIEW: "/pass-history-view",
  GENERATE_PASS_VIEW: "/generate-pass-view",
  GENERATE_VEHICLE_PASS_VIEW:"/generate-vehicle-pass-view",
  GENERATE_TRANSIT_PASS_VIEW:"/generate-transit-pass-view",
  GENERATE_TWO_WHEELER_PASS:"/generate-tWo-wheeler-pass",
  GENERATE_PASS_DOWNLOAD: "/download-pass",
};

const myRoutes = [
  {
    path: ROUTES.DESIGNATION_LIST,
    exact: true,
    page_key: "designation_list",
    component: DesignationList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "officerLogin",
    component: login,
    authenticate: false,
    layout: HomeLayout,
  },

  // {
  //   path: ROUTES.REFUGEE_LOGIN,
  //   exact: true,
  //   page_key: "refugeeLogin",
  //   component: RefugeeLogin,
  //   authenticate: false,
  // },
  {
    path: ROUTES.HOME_DETAILS,
    exact: true,
    page_key: "home_details",
    component: HomeDetails,
    authenticate: false,
  },
  {
    path: ROUTES.EMPTY,
    exact: true,
    page_key: "home_details",
    component: HomeDetails,
    authenticate: false,
  },

  {
    path: ROUTES.TYRE_LIST,
    exact: true,
    page_key: "tyre_list",
    component: TyreList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    exact: true,
    page_key: "change-password",
    component: ChangePassword,
    authenticate: false,
  },

  {
    path: ROUTES.DASHBOARD,
    exact: true,
    page_key: "dashboard",
    component: Dashboard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ALLOCATED_DASHBOARD,
    exact: true,
    page_key: "allocated_dashboard",
    component: AllocatedDashboard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.VEHICLE_LIST}/:status?/:department_id?`,
    exact: true,
    page_key: "vehicle_list",
    component: VehicleList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SERVICE_LIST,
    exact: true,
    page_key: "service_question_list",
    component: ServiceQuestionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RETURN_PAGE}/:id?/:alloted_id?`,
    exact: true,
    page_key: "vehicle_return_details",
    component: ReasonDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DESIGNATION_LIST,
    exact: true,
    page_key: "designation_list",
    component: DesignationList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ENTITY,
    exact: true,
    page_key: "entity",
    component: Entity,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEPARTMENT_LIST,
    exact: true,
    page_key: "department_list",
    component: DEPARTMENT_LIST,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SERVICE_PAGE}/:id`,
    exact: true,
    page_key: "servicePage",
    component: ServicePage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SERVICE_REQUEST_LIST,
    exact: true,
    page_key: "service_list",
    component: ServiceList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SERVICE_REQUEST_DETAILS}/:id?/:service_id?`,
    exact: true,
    page_key: "service_view",
    component: ServiceView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SERVICE_HISTORY_DETAILS}/:id?`,
    exact: true,
    page_key: "service_history",
    component: ServiceHistory,
    layout: layout1,
    authenticate: false,
  },
  // {
  //   path: ROUTES.ALLLOTMENT_LIST,
  //   exact: true,
  //   page_key: "allotment_list",
  //   component: AllotmentList,
  //   layout: layout1,
  //   authenticate: false,
  // },

  {
    path: `${ROUTES.VEHICLE_DETAILS}/:id?`,
    exact: true,
    page_key: "vehicle_details",
    component: VehicleDetails,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.VEHICLE_EDIT}/:id?`,
    exact: true,
    page_key: "vehicle_edit",
    component: VehicleUpdate,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.VEHICLE_ALLOTMENT}/:id?`,
    exact: true,
    page_key: "vehicle_allotment",
    component: VehicleAllotment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CAR_MAKE_LIST,
    exact: true,
    page_key: "CAR_MAKE_LIST",
    component: CarMakeList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CAR_MODEL_LIST}/:id?`,
    exact: true,
    page_key: "carmodel_list",
    component: CarModelList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ACCESSORIES_LIST,
    exact: true,
    page_key: "accessories_list",
    component: AccessoriesList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.AGENCY_LIST,
    exact: true,
    page_key: "agency_list",
    component: AgencyList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CAR_MAKE_DETAILS,
    exact: true,
    page_key: "carmake_details",
    component: CarMakeDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GATE_PASS_LIST,
    exact: true,
    page_key: "gate_pass_list",
    component: GatePassList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GATE_PASS_DETAILS}/:id?/:param_type?`,
    exact: true,
    page_key: "gate_pass_details",
    component: GatePassDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GATE_PASS_VIEW}/:id?`,
    exact: true,
    page_key: "gate_pass_view",
    component: GatePassView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CONDEMNATION_LIST,
    exact: true,
    page_key: "condemnation_list",
    component: CondemnationList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CONDEMNATION_DETAILS}/:id?`,
    exact: true,
    page_key: "condemnation_details",
    component: CondemnationDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FUEL_LIST,
    exact: true,
    page_key: "fuel_list",
    component: FuelList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FUEL_DETAILS}/:id?`,
    exact: true,
    page_key: "fuel_details",
    component: FuelDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FUEL_VIEW}/:id?`,
    exact: true,
    page_key: "fuel_view",
    component: FuelView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DRIVER_LIST}/:type?/:department_id?`,
    exact: true,
    page_key: "driver_list",
    component: DriverList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DRIVER_DETAILS}/:id?`,
    exact: true,
    page_key: "driver_details",
    component: DriverDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.VEHICLE_TAB}/:id?/:paramtype?`,
    exact: true,
    page_key: "vehicle_tab",
    component: VehicleTab,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.VEHICLE_TAB_VIEW}/:id?`,
    exact: true,
    page_key: "vehicle_tab_view",
    component: VehicleTabView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FORGOT_PASSWORD}`,
    exact: true,
    page_key: "forgot_password",
    component: ForgotPassword,
    authenticate: false,
  },
  {
    path: `${ROUTES.NOTIFICATIONS}`,
    exact: true,
    page_key: "notifications",
    component: Notifications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CONDEMNATION_VIEW}/:id?`,
    exact: true,
    page_key: "notifications",
    component: CondemnationView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DRIVER_VIEW}/:id?`,
    exact: true,
    page_key: "driver_view",
    component: DriverView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SCREEN_READER_ACCESS}`,
    exact: true,
    page_key: "screen-reader-access",
    component: ScreenReaderAccess,
    authenticate: false,
  },
  {
    path: `${ROUTES.REACHED_METER_READING}`,
    exact: true,
    page_key: "meter_reading",
    component: ReachedMeterReading,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FUEL_COUPON}`,
    exact: true,
    page_key: "fuel_coupon",
    component: FuelCoupon,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SERVICE_NOT_GIVEN_VEHICLES}`,
    exact: true,
    page_key: "service-not-given-vehicles",
    component: ServiceNotGivenVehicles,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DRIVER_HISTORY}/:id?`,
    exact: true,
    page_key: "history",
    component: DriverHistory,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ALLOCATED_DRIVER_DASHBOARD,
    exact: true,
    page_key: "allocated_driver_dashboard",
    component: AllocatedDriverDashboard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ALLOCATED_DRIVERS_DASHBOARD}/:id?`,
    exact: true,
    page_key: "allocated_drivers_dashboard",
    component: AllocatedDriversDashboard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.OFFICERS,
    exact: true,
    page_key: "officer_list",
    component: OfficerList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.OFFICERS_DETAILS}/:id?`,
    exact: true,
    page_key: "officer_details",
    component: OfficerDetails,
    layout: layout1,
  },
  {
    path: `${ROUTES.PASS_FORM}/:type?`,
    exact: true,
    page_key: "pass-form",
    component: PassForm,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.VEHICLE_PASS}/:id?`,
    exact: true,
    page_key: "new-card",
    component: VehiclePass,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: ROUTES.VEHICLE_PASS_LOGIN,
    exact: true,
    page_key: "vhicle-pass-login",
    component: VehiclePassLogin,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.PASS_HISTORY_VIEW}/:id?`,
    exact: true,
    page_key: "pass-history-view",
    component: PassHistoryView,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATE_PASS_VIEW}/:id?`,
    exact: true,
    page_key: "generate-pass-view",
    component: GeneratePassView,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATE_VEHICLE_PASS_VIEW}/:id?`,
    exact: true,
    page_key: "generate-vehicle-pass-view",
    component: GenerateVehiclePassView,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATE_TRANSIT_PASS_VIEW}/:id?`,
    exact: true,
    page_key: "generate-transit-pass-view",
    component: GenerateTransitPassView,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATE_TWO_WHEELER_PASS}/:id?`,
    exact: true,
    page_key: "generate-two-wheeler-pass",
    component: GenerateTwoWheelerPassView,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATE_PASS_DOWNLOAD}_:id/:value`,
    exact: true,
    page_key: "Pass-Download",
    component: DownloadPass,
    // layout: HomeLayout,
    authenticate: false,
  },
];

export default myRoutes;
